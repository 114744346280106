import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import PostLink from '../components/post-link'

import projectsStyles from './projects.module.css'

const ProjectsPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <Layout>
      <h1 style={{ paddingLeft: '20px' }}>Projects</h1>
      <div className={projectsStyles.container}>{Posts}</div>
    </Layout>
  )
}

ProjectsPage.propTypes = {
  data: PropTypes.object,
}

export default ProjectsPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            image {
              childImageSharp {
                sizes(maxWidth: 630) {
                  ...GatsbyImageSharpSizes
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
