import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import postLinkStyles from './post-link.module.css'

const PostLink = ({ post }) => (
  <Link
    className={postLinkStyles.item}
    title={post.frontmatter.title}
    to={post.frontmatter.path}
  >
    <Img
      sizes={post.frontmatter.image.childImageSharp.sizes}
      className={postLinkStyles.image}
    />
    <h2 className={postLinkStyles.title}>{post.frontmatter.title}</h2>
    <p className={postLinkStyles.date}>{post.frontmatter.date}</p>
  </Link>
)

PostLink.propTypes = {
  post: PropTypes.object,
}

export default PostLink
